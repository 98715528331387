
  @font-face {
    font-display: fallback;
    font-family: "Banana Grotesk";
    src: local("Banana Grotesk Light"), local("BananaGrotesk-Light"),
      url("./fonts/banana_grotesk-light.woff2") format("woff2"),
      url("./fonts/banana_grotesk-light.woff") format("woff");
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-display: fallback;
    font-family: "Banana Grotesk";
    src: local("Banana Grotesk Regular"), local("BananaGrotesk-Regular"),
      url("./fonts/banana_grotesk-regular.woff2") format("woff2"),
      url("./fonts/banana_grotesk-regular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-display: fallback;
    font-family: "Times";
    src: local("Times Roman"), local("Times-Roman"),
      url("./fonts/times-roman.woff2") format("woff2"),
      url("./fonts/times-roman.woff") format("woff");
    font-style: normal;
    font-weight: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
  }

  h1,
  * {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  body {
    background-color: #e5e0da;
    color: #2c2c2c;
    font-family: "Banana Grotesk", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    *::-moz-selection {
      background-color: rgba(255, 255, 255, 0.99);
      color: #2c2c2c;
    }

    *::selection {
      background-color: rgba(255, 255, 255, 0.99);
      color: #2c2c2c;
    }

    @media (prefers-color-scheme: dark) {
      background-color: #171616;
      color: #e5e0da;

      *::-moz-selection {
        background-color: rgba(82, 82, 77, 0.99);
        color: #e5e0da;
      }

      *::selection {
        background-color: rgba(82, 82, 77, 0.99);
        color: #e5e0da;
      }
    }

    @media screen and (min-width: 1280px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
